/**
 *    ***********************************************************************
 *		FINES AND PENALTIES v2
 *		VENDOR FILE
 *
 *		This file contains code that is considered a library
 *		or functionality that is required on every page.
 *
 *    ***********************************************************************
 */

/// LIBRARIES ///
import "whatwg-fetch";					// Polyfill for fetch to work on all browsers
import "alpinejs";						// CSS properties transition library
import Pikaday from "pikaday";			// Datepicker
import Choices from "choices.js";		// Choices dropdown
import Turbolinks from "turbolinks";	// AJAX loading of server-side pages (used for navigation)
import moment from "moment";			// moment.js for date formatting

Turbolinks.start();	// Start loading pages from ajax the moment this script renders (a.k.a from the beginning)

var
	el_Notification = null,				// The notification element that always sits on the top right of the screen
	thread_NotificationDismiss = null;	// A setTimeout() to automatically dismiss the notification after x seconds

const searchSpecificTake = 8;
let currentSkip = 0;
let lastRawSearchQuery;

window.isCurrentlySearching = false;

window.addEventListener("load", () => {
	console.debug("Vendor file has experienced window.load");
});

/**
 * Show a message in the notification element and automatically dismiss it after 5 seconds.
 * @param header The text shown at the top of the notification
 * @param body The text shown as the content of the notification
 * @param icon The icon to be shown on the left of the notification, as an svg element
 */
window.m_ShowAlert = (header, body, icon) => {
	if (thread_NotificationDismiss !== null) clearTimeout(thread_NotificationDismiss);

	document.querySelector("#Notification").querySelector(".header").innerHTML = header;
	document.querySelector("#Notification").querySelector(".body").innerHTML = body;
	document.querySelector("#Notification").querySelector(".icon").innerHTML = icon;

	document.body.__x.$data.notificationVisible = true;
	setTimeout(() => document.body.__x.$data.notificationVisible = false, 5000);
};

/**
 * Invoke m_ShowAlert to show a successful message
 * @param header The text shown at the top of the notification
 * @param body The text shown as the content of the notification
 */
window.m_ShowSuccess = (header, body = "The action was completed successfully.") => m_ShowAlert(header, body, '<svg class="h-6 w-6 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>');

/**
 * Invoke m_ShowAlert to show a warning message
 * @param header The text shown at the top of the notification
 * @param body The text shown as the content of the notification
 */
window.m_ShowWarning = (header, body = "There was a problem with this action.") => m_ShowAlert(header, body, '<svg class="h-6 w-6 text-yellow-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>');

/**
 * Invoke m_ShowAlert to show a error message
 * @param header The text shown at the top of the notification
 * @param body The text shown as the content of the notification
 */
window.m_ShowError = (header, body = "An unknown error occured, please try again later.") => m_ShowAlert(header, body, '<svg class="h-6 w-6 text-red-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>');

/**
 * Executes a fetch request with no modifications to any of the parameters
 * @param url The url to send a request to
 * @param data The data to send with the request
 * @param options Any additional options to be send (like headers, etc)
 * @param showErrorMessage Whether an error notification should be shown automatically on an unsuccessful request (result.success is false or http code is not 2xx)
 * @returns {Promise} A promise. Result is a successful response, Reject is not.
 */
window.m_FetchRaw = (url, options, showErrorMessage) => new Promise((result, reject) => {
	window.fetch(url, options)
		.then(res => res.json())
		.then(res => {
			if (!res.success) {
				if (showErrorMessage) m_ShowError("Error", res.errorMessage);
				return reject(res.errorMessage);
			} else return result(res.data)
		})
		.catch((x) => {
			console.error(x);
			if (showErrorMessage) m_ShowError("Unexpected Error", "Sorry, an unexpected error occured. Please try again later.");
			return reject(x);
		});
});

/**
 * Executes a fetch request with modifications made for normal operations. This request adds a CSRF header and sets the http method to POST.
 * @param url The url to send a request to
 * @param data The data to send with the request
 * @param options Any additional options to be send (like headers, etc)
 * @param showErrorMessage Whether an error notification should be shown automatically on an unsuccessful request (result.success is false or http code is not 2xx)
 * @returns {Promise} A promise. Result is a successful response, Reject is not.
 */
window.m_Fetch = (url, options = {}, showErrorMessage = true) => {
	return new Promise((resolve, reject) => {
		if (options.headers === undefined) options.headers = {};
		if (options.headers.RequestVerificationToken === undefined) options.headers.RequestVerificationToken = document.querySelector("#__AjaxAntiForgeryForm input[name=__RequestVerificationToken]").value;
		if (options.method === undefined) options.method = "GET";
		window.m_FetchRaw(url, options, showErrorMessage)
			.then(resolve)
			.catch(reject);
	});
};

window.m_FetchGet = (url, data = {}, options = {}, showErrorMessage = true) => {
	options.method = "GET";
	let formData;
	if (data instanceof FormData) formData = data;
	else formData = window.arrayToFormData(data);
	
	let encodedData = new URLSearchParams(formData).toString();
	return window.m_Fetch(url + (encodedData !== "" ? "?" + encodedData : ""), {}, options, showErrorMessage);
}

window.m_FetchPost = (url, data = {}, options = {}, showErrorMessage = true) => {
	options.method = "POST";
	let formData;
	if (data instanceof FormData) formData = data;
	else formData = window.arrayToFormData(data);
	
	options.body = formData;
	return window.m_Fetch(url, options, showErrorMessage);
}

window.m_FetchPut = (url, data = {}, options = {}, showErrorMessage = true) => {
	options.method = "PUT";
	options.body = window.arrayToFormData(data);
	return window.m_Fetch(url, options, showErrorMessage);
}

window.m_FetchDelete = (url, data = {}, options = {}, showErrorMessage = true) => {
	options.method = "DELETE";
	options.body = window.arrayToFormData(data);
	return window.m_Fetch(url, options, showErrorMessage);
}

window.arrayToFormData = (obj) => {
	let formData = new FormData();
	for (const [key, value] of Object.entries(obj)) {
		if (value instanceof Array) {
			value.forEach(item => {
				formData.append(`${key}[]`, item);
			})
		} else {
			formData.append(key, value);
		}
	}
	
	return formData;
}

/**
 * Snips a string to its desired length, adding … at the end.
 * @param string The string to length check
 * @param length How long the string should be at max before adding …
 * @returns {string|*} The string that's compliant with the length requirement
 */
window.m_Truncate = (string, length) => string.length > length ? string.slice(0, length) + "…" : string;

/**
 * Serializes the data array to be able to be sent as fetch
 * @param data The data to serialize
 * @returns {URLSearchParams} The data that can be sent as fetch
 */
window.serialize = (data) => {
	var urlParams = new URLSearchParams();
	if (data instanceof HTMLFormElement) {
		for (let pair of new FormData(data))
			urlParams.append(pair[0], pair[1]);
	} else {
		for (let pair in data)
			urlParams.append(pair, data[pair]);
	}
	return urlParams;
};

/**
 * Powers the top bar search.
 * @param searchQuery this is the query to search for
 * @param enterHit the user hit enter so they want this to be a fresh new search
 */
window.m_Search = (searchQuery, enterHit) => {
	// If we are already searching, let's not interrupt.
	if (window.isCurrentlySearching || searchQuery === "") return;
	window.isCurrentlySearching = true;

	// This is the endpoint we will hit by default. This will be overridden if necessary by query attributes
	let endpoint = "/api/Search/All";
	// The data we will send to the endpoint
	let data;
	// The full query the user typed in sans manipulation done below.
	let rawSearchQuery = searchQuery;
	
	// If the search query has changed since last time, reset the in-memory pagination (also resets if the user forces a search by hitting enter)
	if (lastRawSearchQuery !== rawSearchQuery || enterHit) currentSkip = 0;
	
	// If the user has gone to the end of results and then gone back to the start and the current skip is somehow less than 0, set it to 0 (cannot query results -4 to -1 par example
	if (currentSkip < 0) currentSkip = 0;

	// If the user prefixes the request with id: then they are looking for a fine with a specific TTS Reference number, so change the endpoint to search for these (so it's faster)
	if (searchQuery.startsWith("id:")) {
		searchQuery = searchQuery.substr(3);	// Remove id: from the query
		data = {query: searchQuery};				// The data to send, this should only ever return 1 row so no need for skip and take
		endpoint = "/api/Search/Id";				// The id specific endpoint
	} else if (searchQuery.startsWith("pcn:")) {	// If they are prefixed with pcn: then they are looking for a fine by a PCN or other reference specified, so do a similar process as above
		searchQuery = searchQuery.substr(4);	// Remove pcn: from the query
		data = {query: searchQuery, skip: currentSkip, take: searchSpecificTake}; // The data to send, including current pagination progress
		endpoint = "/api/Search/PCN";				// The pcn specific endpoint
	} else if (searchQuery.startsWith("registration:")) {	// You should know what this does by now.
		searchQuery = searchQuery.substr(13);
		data = {query: searchQuery, skip: currentSkip, take: searchSpecificTake};
		endpoint = "/api/Search/Registration";
	} else if (searchQuery.startsWith("client:")) {
		searchQuery = searchQuery.substr(7);
		data = {query: searchQuery, skip: currentSkip, take: searchSpecificTake};
		endpoint = "/api/Search/Client";
	} else {
		data = {query: searchQuery, skip: 0, take: 3};	// If none of the above match, we are still searching for all, in which case we'll show the first three of each group it finds (pcn, registration, client
														// and then let the user figure out the more specifics
	}

	const searchResults = document.getElementById("SearchResults");	// The box on the page handling the search results
	let noResults = true;														// If we have no results, we'll set this so we know to show the status box at the end

	document.body.__x.$data.searching = true;	// Alpine.js UI magic (basically shows the loading spinner instead of the magnifying glass)
	
	lastRawSearchQuery = rawSearchQuery;		// Now we're done with validation, this query is now the last query we've ran (for validation next time)
	
	m_FetchGet(endpoint, data)
		.then(data => {
			searchResults.innerHTML = "";		// Remove all current search data
			data.forEach(group => {				// And now, for each search group...
				if (group.fines.length !== 0) {	// If it has results, let's create the results view for the group
					let liElement = document.createElement("li");	// New list group
					liElement.classList.add("px-6", "py-2");				// Tailwind CSS

					let 
						groupHeader,		// The header to show for the group
						showMorePrefix;		// The prefix to add to the query for more specific searching when the user clicks "Show More"
					switch (group.group) {
						case "Id":
							groupHeader = "By Id / TTS Reference";
							showMorePrefix = "id";
							break;
						case "PCN":
							groupHeader = "By PCN Number";
							showMorePrefix = "pcn";
							break;
						case "Registration":
							groupHeader = "By Vehicle Registration";
							showMorePrefix = "registration";
							break;
						case "Client":
							groupHeader = "By Client";
							showMorePrefix = "client";
							break;
						default:						// Should never get to here, but just in case...
							groupHeader = "Assorted";
							showMorePrefix = "";
							break;
					}
					
					if (endpoint !== "/api/Search/All") // If this is a specific search, we will show pagination in the header to show where aboutst the search is
						groupHeader = `${groupHeader} (${currentSkip + 1} to ${currentSkip + searchSpecificTake} of ${group.total})`;
					else	// Otherwise, just show how many there are in the database for each category (PCN found 1337 results, etc)
						groupHeader = `${groupHeader} (${group.total} result${group.total === 1 ? "" : "s"})`;

					// Now we know the content of the header, lets show it in the results box
					let groupHeaderElement = document.createElement("span");
					groupHeaderElement.classList.add("text-xs", "text-gray-400", "uppercase", "truncate");
					groupHeaderElement.innerText = groupHeader;
					liElement.appendChild(groupHeaderElement);

					// And now, for the results themselves
					let finesListElement = document.createElement("ul");

					group.fines.forEach(fine => {
						noResults = false;	// We'll get to here if there is at least 1 result; that means there are results so what do we set noResults to? hmmm...
						let fineLiElement = document.createElement("li");
						fineLiElement.classList.add("mb-2");	// Imagine typing the below out as createElement and classList add, lol no thanks!
						fineLiElement.innerHTML = `	
						<div class="flex items-center space-x-4">
							<div class="flex-1 min-w-0">
								<p class="text-sm font-medium text-gray-900 truncate">
									F${String(fine.id).padStart(6, '0')}
									&bull;
									${fine.pcnNumber}
								</p>
								<p class="text-sm text-gray-500 truncate">
									${fine.fineStatus}
									&bull;
									${fine.childClient}
									(${fine.parentClient})
								</p>
							</div>
							<div>
								<a class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50" href="/fine/${fine.id}">
									View
								</a>
							</div>
						</div>`
						finesListElement.appendChild(fineLiElement); // Add this to the group of fines we found
					});

					// Okay, now the search group has all its fines added into it it needs to show, add it to the master box of search results
					
					liElement.appendChild(finesListElement);

					// If this is a specific search, show some pagination
					if (endpoint !== "/api/Search/All" && group.fines !== undefined) {
						let paginationToolbar = document.createElement("div");
						// If current skip is 0, there are no previous pages so do not show the previous button
						if (currentSkip !== 0) {
							let previousPage = document.createElement("span");
							previousPage.innerText = "Previous";
							previousPage.classList.add("inline-flex", "items-center", "shadow-sm", "px-2.5", "py-0.5", "border", "border-gray-300", "text-sm", "leading-5", "font-medium", "rounded-full", "text-gray-700", "bg-white", "hover:bg-gray-50", "cursor-pointer", "mr-1");
							previousPage.onclick = () => {
								previousPage.innerHTML = "<i class=\"fas fa-circle-notch fa-spin\"></i>"
								currentSkip = currentSkip - searchSpecificTake;
								window.m_Search(rawSearchQuery, false);
							};
							paginationToolbar.appendChild(previousPage);
						}

						// If the number of fines returned is less than the specified take, there are no more pages so do not show the next button
						if (group.fines.length === 8) {
							let nextPage = document.createElement("span");
							nextPage.innerText = "Next";
							nextPage.classList.add("inline-flex", "items-center", "shadow-sm", "px-2.5", "py-0.5", "border", "border-gray-300", "text-sm", "leading-5", "font-medium", "rounded-full", "text-gray-700", "bg-white", "hover:bg-gray-50", "cursor-pointer");
							nextPage.onclick = () => {
								nextPage.innerHTML = "<i class=\"fas fa-circle-notch fa-spin\"></i>"
								currentSkip = currentSkip + searchSpecificTake;
								window.m_Search(rawSearchQuery, false);
							};
							paginationToolbar.appendChild(nextPage);
						}
						liElement.appendChild(paginationToolbar);
					}
					// If it's not specific, give the option to show more of them.
					else {
						let showMoreToolbar = document.createElement("div");
						let showMoreButton = document.createElement("span");
						showMoreButton.innerText = "Show More";
						showMoreButton.classList.add("inline-flex", "items-center", "shadow-sm", "px-2.5", "py-0.5", "border", "border-gray-300", "text-sm", "leading-5", "font-medium", "rounded-full", "text-gray-700", "bg-white", "hover:bg-gray-50", "cursor-pointer");
						showMoreButton.onclick = () => {
							document.getElementById("search").value = `${showMorePrefix}:${document.getElementById("search").value}`;
							window.m_Search(document.getElementById("search").value, true);
						};
						showMoreToolbar.appendChild(showMoreButton);
						liElement.appendChild(showMoreToolbar);
					}
					searchResults.appendChild(liElement);
				}
			});
			// Status message for no fines found
			if (noResults) searchResults.innerHTML = "<li><p class=\"text-xs text-gray-400 truncate py-2 text-center\">No results found.</p></li>"

			// All done, so lets tell the system we're done searching...
			window.isCurrentlySearching = false;
			// ... show the results ...
			document.body.__x.$data.searchResultsVisible = true;
			// ... and stop the magnifying glass spinning ...
			document.body.__x.$data.searching = false;
			// ... and now we're really done.
		})
		.catch(_ => {	// Error went wrong somewhere? Cleanup so this doesn't softlock searching until the user refreshes
			window.isCurrentlySearching = false;
			document.body.__x.$data.searching = false;
		});
}

/**
 * Make Pikaday accessible to other bundles (main, admin)
 */
window.Pikaday = Pikaday;

/**
 * Make Choices accessible to other bundles (main, admin)
 */
window.Choices = Choices;

/**
 * Make moment accessible to other bundles (main, admin)
 */
window.moment = moment;

/**
 * Make turbolinks accessible to other bundles (main, admin)
 */
window.turbolinks = Turbolinks;